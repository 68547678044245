import { library } from '@fortawesome/fontawesome-svg-core';
import { faTable, faTrashAlt, faEdit, faEye, faToggleOn, faToggleOff, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BrowserRouter as Router, Route, Routes, Link, useLocation, json, useNavigate } from 'react-router-dom';
import React, { useState, useEffect, createContext } from 'react';
import ReactQuill from 'react-quill';
import DeleteConfirmaModal from './deletModalBox';
import LoadingSpiner from './utilities/loadingSpinner';


const toolbarOptions = [
    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' },
    { 'indent': '-1' }, { 'indent': '+1' }],
    ['link', 'image', 'video'],
    [{ 'align': [] }],
    [{ 'color': [] }, { 'background': [] }],
    ['clean'], // remove formatting button
    ['copy', 'paste', 'undo', 'redo'] // custom buttons
];

const modules = {
    toolbar: {
        container: toolbarOptions,
        handlers: {
            'copy': () => document.execCommand('true'),
            'paste': () => document.execCommand('paste'),
            'undo': () => document.execCommand('undo'),
            'redo': () => document.execCommand('redo'),
        }
    }
};


library.add(faTable, faTrashAlt, faEdit, faEye, faToggleOn, faToggleOff, faArrowLeft, faArrowRight);

function Advertisements() {

    const location = useLocation(); // Use the useLocation hook
    const isEditPage = location.pathname.includes('/admin/advertisements/edit');
    const isAddPage = location.pathname.includes('/admin/advertisements/add');
    const [advertisementDetail, advertisementUpdate] = useState([]);
    // add, edit delet view gussing
    const [description, setDescription] = useState('Add Description');
    const [formData, setFormData] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [currentId, setCurrentId] = useState(null);
    const [loading, setLoading] = useState(true); // loading state

    // const [paginationAdd, setPaginationAdd] = useState([])
    const [itemsPerPage, setItemPerPage] = useState([10]);
    const [currentPage, setCurrentPage] = useState(1);
    // Function to handle the toggle

    const handleRowClick = (row) => {
        setFormData(row);
    };

    useEffect(() => {
        setCurrentPage(1);
        // setPaginationAdd(Math.ceil(advertisementDetail?.length / itemsPerPage))
        // console.log(advertisementDetail?.length)
    }, [])
    // pagination setting
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    // divinding to show per page
    const paginatedAdvertisement = advertisementDetail.slice(indexOfFirstItem, indexOfLastItem);
    // console.log('paginatedAdvertisement', paginatedAdvertisement)
    // console.log('addPage', paginationAdd)


    const handleDelete = (id) => {
        setCurrentId(id);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setCurrentId(null);
    };

    const handleConfirmDelete = () => {

        const id = currentId;
        const deleteData = advertisementDetail.find(item => item.id === id);

        fetch('https://api.sattakingvip.co.in/advertisementUpdate', {
            method: 'POST', // or 'PUT' depending on your requirements
            headers: {
                'Content-Type': 'application/json', // specify the content type
            },
            body: JSON.stringify({ ...deleteData, isDeleted: true }),
        })
            .then(response => response.json())
            .then(json => {
                advertisementUpdate(json);
                setLoading(false);

            })
            .catch(error => {
                console.error(error);
                setLoading(false)
            });

        const updatedData = advertisementDetail.filter(item => item.id !== id);
        advertisementUpdate(updatedData);

        handleCloseModal();

    }

    useEffect(() => {
        const currentUrl = window.location;
        if (currentUrl.pathname.includes('/admin/advertisements/edit')) {
            setDescription('Edit Description');
        } else {
            setFormData({})
            setDescription('Add Description');
        }
    }, [location.pathname]);

    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1);
    };



    useEffect(() => {
        fetch('https://api.sattakingvip.co.in/advertisementUpdate', {
            method: 'POST', // or 'PUT' depending on your requirements
            headers: {
                'Content-Type': 'application/json', // specify the content type
            },
            body: JSON.stringify({}),
        })
            .then(response => response.json())
            .then(json => {
                advertisementUpdate(json)
                setLoading(false);
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
            });
    }, []);

    const handleChange = (content, delta, source, editor) => {

        setFormData({
            ...formData,
            description: content
        });
    };

    // Handle input change
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        let isAdd = false
        let isEdit = false
        if (isAddPage) {
            isAdd = true
        }
        if (isEditPage) {
            isEdit = true
        }
        fetch('https://api.sattakingvip.co.in/advertisementUpdate', {
            method: 'POST', // or 'PUT' depending on your requirements
            headers: {
                'Content-Type': 'application/json', // specify the content type
            },
            body: JSON.stringify({ ...formData, addPage: isAdd, editPage: isEdit }),
        })
            .then(response => response.json())
            .then(json => {
                advertisementUpdate(json)
                // console.log(json);

            })
            .catch(error => console.error(error));

        navigate(-1);

    };
    const handleCountChange = (event) => {
        setItemPerPage(event.target.value)
        // Handle the change (e.g., update the state or filter data)
    };
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const paginationControls = (totalPages) => (
        <div className="col-12">
            <div className="row d-flex justify-content-between">
                {/* <div className="row"> */}

                <button className="btn btn-primary float-start col-md-3 col-sm-3" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                    {'< Previous'}
                </button>

                {/* <div className="col-md-6 text-center">
                    <span>show per page <select name='perPage' onChange={handleCountChange} className="form-control col-md-2 text-center">
                        <option value='10'>10</option>
                        <option value='20'>20</option>
                        <option value='30'>30</option>
                        <option value='40'>40</option>
                    </select></span>

                </div> */}
                <button className="btn btn-primary float-end col-md-3 col-sm-3" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} >
                    {'Next >'}
                </button>
            </div>
        </div>
    );


    const [toggleStates, setToggleStates] = useState(advertisementDetail.reduce((acc, row) => ({ ...acc, [row.id]: false }), {}));
    const handleToggle = (id) => {
        setToggleStates((prevStates) => ({ ...prevStates, [id]: !prevStates[id] }));
    };
    return (
        <div className="card mt-5">
            <div className='border'>
                {
                    (!(isEditPage || isAddPage)) &&
                    (
                        <div>
                            <div className="card-header">
                                <div className="col-12">
                                    <div className="row d-flex">
                                        <div className="col-6">
                                            {/* <span className="badge bg-dark"><FontAwesomeIcon className='fonticon' icon="table" />Advertisement</span> */}

                                            <span className="badge bg-dark position-relative">
                                                <FontAwesomeIcon className='fonticon' icon="table" />Advertisement
                                                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                                    {advertisementDetail.length}
                                                </span>
                                            </span>
                                        </div>
                                        <div className="col-6">
                                            <button className="btn btn-primary float-end">
                                                <Link to="/admin/advertisements/add" className='nav-link'>Add Advertisement</Link>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='gussing table-responsive'>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Sr. No</th>
                                            <th>Description</th>
                                            <th>Name</th>
                                            <th>Post</th>
                                            <th>Mobile No.</th>
                                            <th>Action</th>

                                        </tr>
                                    </thead>
                                    {/* <tbody className='ad_description'>
                                    {advertisementDetail?.map((row, index) => (
                                        <tr key={row?.id}>
                                            <td>{index + 1}</td>
                                            <td className='desc'>{row?.description}</td>
                                            <td>{row?.name}</td>
                                            <td>{row?.post}</td>
                                            <td>{row?.mobile}</td>
                                            <td className=''>
                                                <FontAwesomeIcon key={row?.id} className='form-switch-on' id={row?.id} icon={toggleStates[row?.id] ? faToggleOff : faToggleOn} size="1x" onClick={() => handleToggle(row?.id)} style={{ cursor: 'pointer' }} />
                                                <FontAwesomeIcon icon="trash-alt" style={{ cursor: 'pointer' }} className='form-switch ' onClick={() => handleDelete(row?.id)} />
                                                <Link to="/admin/advertisements/edit" className='nav-link form-switch'><FontAwesomeIcon size="1x" icon="edit" onClick={() => handleRowClick(row)} /></Link>

                                            </td>
                                        </tr>
                                    ))}
                                </tbody> */}
                                    {loading ? (<LoadingSpiner />) :
                                        <tbody className='ad_description'>
                                            {paginatedAdvertisement ? (
                                                paginatedAdvertisement?.length > 0 ? (
                                                    paginatedAdvertisement?.map((row, index) => (
                                                        <tr key={row?.id}>
                                                            <td>{index + 1 + indexOfFirstItem}</td>
                                                            <td className='desc'>{row?.description}</td>
                                                            <td>{row?.name}</td>
                                                            <td>{row?.post}</td>
                                                            <td>{row?.mobile}</td>
                                                            <td className=''>
                                                                <FontAwesomeIcon key={row?.id} className='form-switch-on' id={row?.id} icon={toggleStates[row?.id] ? faToggleOff : faToggleOn} size="1x" onClick={() => handleToggle(row?.id)} style={{ cursor: 'pointer' }} />
                                                                <FontAwesomeIcon icon="trash-alt" style={{ cursor: 'pointer' }} className='form-switch ' onClick={() => handleDelete(row?.id)} />
                                                                <Link to="/admin/advertisements/edit" className='nav-link form-switch'><FontAwesomeIcon size="1x" icon="edit" onClick={() => handleRowClick(row)} /></Link>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="6">No Advertisement Found</td>
                                                    </tr>
                                                )
                                            ) : (
                                                <tr>
                                                    <td colSpan="6">No Advertisement Found</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    }

                                    <DeleteConfirmaModal
                                        show={showModal}
                                        handleClose={handleCloseModal}
                                        handleConfirm={handleConfirmDelete}
                                    />
                                </table>
                            </div>
                            <div className="card-footer">
                                {(advertisementDetail) && (
                                    <>
                                        {paginationControls(Math.ceil(advertisementDetail?.length / itemsPerPage))}
                                    </>
                                )}


                            </div>
                        </div>
                    )}

                {
                    (isEditPage || isAddPage)
                    && (
                        <div className='addGussing' style={{ display: 'block' }}>
                            <div className="">
                                {/* {row} */}
                                <div className="card-header">
                                    <div className="col-12">
                                        <div className="row d-flex">
                                            <div className="col-6">
                                                <span className="badge bg-dark">{description}</span>
                                            </div>
                                            <div className="col-6">
                                                <button className="btn btn-dark float-end" onClick={handleBackClick}>
                                                    <FontAwesomeIcon icon="arrow-left" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="p-3">
                                    <form id="xyz" className="align-items-center" onSubmit={handleSubmit}>
                                        <div className=''>
                                            <ReactQuill value={formData.description} onChange={handleChange} modules={modules} className="mt-5" />
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">Name</label>
                                            <input type="text" name="name" value={formData?.name} onChange={handleInputChange} className="form-control" required />
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">Post</label>
                                            <input type="text" name="post" value={formData?.post} onChange={handleInputChange} className="form-control" required />
                                        </div>
                                        <div className="form-group pt-4">
                                            <label className="form-label">Mobile Number</label>
                                            <input type="number" name="mobile" value={formData?.mobile} onChange={handleInputChange} className="form-control" required />
                                        </div>
                                        {
                                            !(false) && (
                                                <div className="form-group pt-4" style={{ display: 'block' }}>
                                                    <button type="submit" className="btn btn-info" >Submit</button>
                                                </div>
                                            )

                                        }
                                    </form>
                                </div>
                            </div>
                        </div>
                    )}
            </div>
        </div>
    )

}

export default Advertisements;